import { FeatureToggleServiceConfig, isOn, set } from 'feature-toggle-service';

export type LicenseToggleKey =
  'licenseSavedSearches'
  | 'licensePersonalLists'
  | 'licenseCustomShowcases'
  | 'licenseReadingHistory'
  | 'licenseDiscovery'
  | 'licenseDiscoverPlus'
  | 'licenseDiscoverPremium'
  | 'licenseDiscoverConsortia';

export const customerFeatureToggles = [
  'DIS-27818_2023-07-01_advanced_search',
  'VE-2814_2023-09-30_announcements',
  'VE-3646_2023-12-31_staff_announcements',
  'DIS-30793_2024-04-27_auth_patron',
  'VE-3951_2024-04-30_discover-one-trust-banner',
  'DIS-31141_2024-06-30_passthrough_search',
  'VE-2513_2023-09-30_kids_catalog',
  'VE-2769_2024-01-31_program-events',
  'DIS-31747_2024-06-01_patron_ui_maintenance_page',
  'DIS-31589',
  'VE-4281_2024-03-11_HomePageTheme',
  'DIS-28885_2024-06-31_syndetics_integration',
  'DIS-30364_2024-06-30_Social-Media-Share',
  'DIS-32351_2024-07-31_Syndetics-Links',
  'VE-4791_2024-07-31_pass_item_language',
  'VE-4775_2024-07-31_format_facet_search',
  'DIS-31554_2024-08-30_schedule_showcase',
  'DIS-32415_10-31-24_showcase-search'
] as const;
export type CustomerFeatureToggleKey = typeof customerFeatureToggles[number];

// search for featureExample to find things you should set when adding feature flag
export type CombinedToggleKey = LicenseToggleKey | CustomerFeatureToggleKey;

export type CombinedToggleData = FeatureToggleServiceConfig & {
  [key in CombinedToggleKey]?: boolean;
};

export interface FeatureToggleServiceLibrary {
  isOn: typeof isOn;
  set: typeof set;
}

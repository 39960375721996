import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { WindowRefService } from 'app/services/window-ref.service';
import { ShareItShareEntityType } from 'app/share-it/models/share-it';
import { InformationModalComponent } from 'common/components/information-modal/information-modal.component';
import { combineLatest, EMPTY, merge, of, zip } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  first,
  map,
  mergeMap,
  pluck,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { endGuestSession, toggleBookmarks } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import * as UserReducer from 'user/reducers/user.reducer';
import * as CustomShowcaseActions from '../../custom-showcase/actions/custom-showcase.actions';
import { CustomShowcaseCreatedFromType } from '../../custom-showcase/models/custom-showcase';
import { PersonalList } from '../../entity/models/entity';
import { loggedIn, staffLoggedIn } from '../../keycloak/actions/keycloak.actions';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak } from '../../keycloak/reducers/keycloak.reducer';
import { KeycloakService } from '../../keycloak/services/keycloak.service';
import { ShareOnSocialMediaService, SocialMediaType } from '../../services/share-on-social-media.service';
import * as ShareItActions from '../../share-it/actions/share-it.actions';
import * as ForLaterListActions from '../actions/for-later-list.actions';
import * as ListActions from '../actions/list.actions';
import { copyUrl, writeUrl, shareOnFacebook, shareOnTwitter, redirectToSocialMediaSuccess } from '../actions/list.actions';
import { SaveMultipleBookmarksModalComponent } from '../components/save-multiple-bookmarks-modal/save-multiple-bookmarks-modal.component';
import { BookmarkButtonState } from '../models/bookmark-button-state';
import { BookmarkedStatus, DeleteListWithShowcaseError, ListError, ListType, LoadListItemsPayload } from '../models/list';
import { ListPaginationParams } from '../models/list.dto';
import { SelectListTrigger } from '../models/select-list';
import * as ForLaterListReducer from '../reducers/for-later-list.reducer';
import * as ListReducer from '../reducers/list.reducer';
import { ListTransformerService } from '../services/list-transformer.service';
import { ListService } from '../services/list.service';
import { ModalQueueService } from '../services/modal-queue.service';

@Injectable()
export class ListEffects {
  public static readonly queryParamSaveBookmarks = 'saveBookmarks';
  public static readonly queryParamValueBookmarksTab = 'bookmarksTab';
  public static listItemsPrefetched = 10;
  private static readonly pageSize = 50;

  public shareList$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.openEmailModal),
    map((sharedList) => ListActions.getListComplete({id: sharedList.sharedList.id}))
  ));


  public getListUrlComplete$ = createEffect(() => (
    merge(
      this.actions$.pipe(ofType(ListActions.getListComplete))
        .pipe(map(({id}) => this.makeListUrl(id)))
    )
      .pipe(
        withLatestFrom(this.store.select(ListReducer.sharedList)),
        map(([url, sharedList]) => {
          return ShareItActions.openEmailEntityModal({
            defaultSubject: sharedList.name,
            resource: {
              type: ShareItShareEntityType.LIST,
              attributes: {url, resourceName: sharedList.name},
            },
          });
        }),
      )
  ))

  public addBookmarkViaButtonToList$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.addBookmarkViaButton),
    withLatestFrom(this.store.select(ListReducer.getDefaultList)),
    map(([{entity}, defaultList]) => (
      ListActions.moveBookmarks({
        entities: [entity],
        toListIds: [defaultList?.id],
        actionsOnSuccess: [ListActions.setBookmarkButtonState({
          entityId: entity.id,
          bookmarkButtonState: BookmarkButtonState.SAVED_TO_SINGLE_LIST,
          justAddedListId: defaultList?.id,
        })],
      })
    )),
  ));

  public removeItemViaButtonSingleList$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.removeBookmarkViaButtonSingleList),
    map(({entity, listId}) => (
      ListActions.moveBookmarks({
        entities: [entity],
        fromListIds: [listId],
        actionsOnSuccess: [ListActions.resetEntityBookmarkedState({entityId: entity.id})],
      })
    )),
  ));

  public moveBookmarks$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.moveBookmarks),
    switchMap(({fromListIds = [], toListIds = [], entities, actionsOnSuccess = [], actionsOnFail = []}) => {
      const normalizedEntities = entities.map(entity => {
        return entity.selectedTabRecordId ?
          entity :
          { ...entity, selectedTabRecordId: (entity.personalLists || entity.editionsData || [])[0]?.recordId };
      });

      return this.listService.patchItems({
        addTo: toListIds,
        deleteFrom: fromListIds,
        items: normalizedEntities.map((entity) => this.listService.makePatchItem(entity)),
      })
        .pipe(
          switchMap(() => {
            const actions: Action[] = [];
            if (toListIds.length) {
              actions.push(ListActions.addBookmarksComplete({entities: normalizedEntities, listIds: toListIds}));
            }
            if (fromListIds.length) {
              actions.push(ListActions.removeBookmarksComplete({entities: normalizedEntities, listIds: fromListIds}));
            }
            if (toListIds.length || fromListIds.length) {
              actions.push(ListActions.reloadLists({listIds: [...fromListIds, ...toListIds]}));
            }

            return of(...actions, ...actionsOnSuccess);
          }),
          catchError((err: HttpErrorResponse) => {
            const error = ListEffects.extractErrorOrUnknown(err);
            return of(ListActions.moveBookmarksFailure({entities: normalizedEntities, toListIds, fromListIds, error}), ...actionsOnFail);
          }),
        );
    }),
  ));

  public moveBookmarksViaSelectList$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.moveBookmarksViaSelectList),
    map(({entities, fromListIds, toListIds, trigger}) => {
      const actionsOnSuccess: Action[] = [ListActions.moveBookmarksViaSelectListDone({
        entities,
        fromListIds,
        toListIds
      })];
      if (trigger === SelectListTrigger.BOOKMARK_BUTTON_SINGLE_ENTITY) {
        actionsOnSuccess.push(
          ListActions.setBookmarkButtonState({
            entityId: entities[0].id,
            bookmarkButtonState: BookmarkButtonState.SAVED_TO_SINGLE_LIST,
            justAddedListId: toListIds[0],
          }),
          ListActions.setDefaultListId({id: toListIds[0]}),
        );
      }

      return ListActions.moveBookmarks({
        entities, fromListIds, toListIds, actionsOnSuccess, actionsOnFail: [
          ListActions.moveBookmarksViaSelectListFailure(),
        ],
      });
    }),
  ));

  public reloadLists$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.reloadLists),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.select(ListReducer.getLists)),
    )),
    switchMap(([{listIds}, lists]) => (
      listIds.flatMap((id) => {
        const list = lists.find((list) => list.id === id);
        const actions: Action[] = [];
        if (list) {
          const loadListItemsPayload: LoadListItemsPayload = {
            id,
            sort: list.sort,
            paginationParams: this.makePaginationParams(0, ListEffects.pageSize),
          };
          if (list.showcaseRef) {
            loadListItemsPayload.notifyShowcaseId = list.showcaseRef;
          }

          actions.push(
            ListActions.clear({id}),
            ListActions.loadListItems(loadListItemsPayload),
          );
        }

        return actions;
      })
    )),
  ));

  public removeSelectedFromListAuth$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.removeSelectedFromList),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.select(ListReducer.getSelectedListItemEntities, {id: action.listId})),
    )),
    filter(([, entities]) => !!entities),
    map(([{listId}, entities]) => ListActions.moveBookmarks({fromListIds: [listId], entities})),
  ));

  public removeSelectedFromListGuest$ = createEffect(() => this.actions$.pipe(
    ofType(ForLaterListActions.removeSelectedFromList),
    withLatestFrom(this.store.select(ForLaterListReducer.getSelectedListItemEntities)),
    filter(([, entities]) => !!entities),
    switchMap(([, entities]) => of(
      ForLaterListActions.removeSelectedFromListComplete(),
      ListActions.resetEntitiesBookmarkedState({entityIds: entities.map((entity) => entity.id)}),
    )),
  ));

  public createList$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.createList),
    switchMap(({name}) => {
      return this.listService.createList(name)
        .pipe(
          map((listCreate) => ListActions.createListComplete({
            list: {
              ...listCreate,
              name,
              type: ListType.regular,
              sort: {field: 'date', order: 'asc'},
            },
          })),
          catchError((err: HttpErrorResponse) => {
            const error = ListEffects.extractErrorOrUnknown(err);
            return of(ListActions.createListFailure({error}));
          }),
        );
    }),
  ));

  public updateList$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.updateList),
    switchMap(({request}) => {
      return this.listService.updateList(request)
        .pipe(
          map((listUpdate) => ListActions.updateListComplete({listUpdate})),
          catchError((err: HttpErrorResponse) => {
            const error = ListEffects.extractErrorOrUnknown(err);
            return of(ListActions.updateListFailure({id: request.id, error}));
          }),
        );
    }),
  ));

  // Before deleting a list, we should be certain that linked showcase (if any) is deleted first,
  // so we remove the latter and retrigger the effect. If showcase is not deleted, we can't proceed
  public deleteList$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.deleteList),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.select(ListReducer.getList, {id: action.id})),
      withLatestFrom(this.store.select(UserReducer.doesUserHavePermission(UserPermission.SHOWCASES_PERSONAL_DELETE))),
    )),
    switchMap(([[{id, showcaseRemoveResult}, list], canDeleteShowcase]) => {
      if (showcaseRemoveResult === 'error') {
        return of(ListActions.deleteListFailure({id, error: {showcase: true}}));
      } else if (showcaseRemoveResult !== 'success') {
        if (list.showcaseRef) {
          if (canDeleteShowcase) {
            return of(CustomShowcaseActions.remove({
              id: list.showcaseRef,
              createdFrom: {id, name: list.name, type: CustomShowcaseCreatedFromType.list},
              actionOnSuccess: ListActions.deleteList({id, showcaseRemoveResult: 'success'}),
              actionOnFailure: ListActions.deleteList({id, showcaseRemoveResult: 'error'}),
            }));
          } else {
            return of(ListActions.deleteListFailure({id, error: {showcase: true}}));
          }
        }
      }

      return this.listService.deleteList(id)
        .pipe(
          map((id) => ListActions.deleteListComplete({id})),
          catchError((err: HttpErrorResponse) => {
            const error: DeleteListWithShowcaseError = {list: ListEffects.extractErrorOrUnknown(err)};
            if (showcaseRemoveResult === 'success') {
              error.showcase = false;
            }

            return of(ListActions.deleteListFailure({id, error}));
          }),
        );
    }),
  ));

  public changeListSort$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.changeListSort),
    switchMap(({id, sort}) => [
      ListActions.clear({id}),
      ListActions.loadListItems({id, sort, paginationParams: this.makePaginationParams(0, ListEffects.pageSize)}),
    ]),
  ));

  public loadMoreItems$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.loadMoreListItems),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.select(ListReducer.getList, {id: action.id})),
    )),
    filter(([, list]) => !!list?.pagination && !ListReducer.isLastPageLoaded(list)),
    map(([, list]) => ListActions.loadListItems({
      id: list.id,
      sort: list.sort,
      paginationParams: this.makePaginationParams(list.pagination.page + 1, ListEffects.pageSize),
    })),
  ));

  public loadFirstPageOfItemsOnListOpen$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.openList),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.select(ListReducer.getList, {id: action.listId})),
    )),
    filter(([, {pagination}]) => pagination?.page === 0),
    map(([, {id, sort}]) => ListActions.loadListItems({
      id,
      sort,
      paginationParams: this.makePaginationParams(0, ListEffects.pageSize),
      clearBeforeAdd: true,
    })),
  ));

  public loadItems$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.loadListItems),
    mergeMap(({id, sort, paginationParams, clearBeforeAdd, notifyShowcaseId}) => {
      const sortByString = this.listTransformerService.transformSortToListSortByString(sort);
      return this.listService.getItems(id, sortByString, paginationParams)
        .pipe(
          switchMap((listItemsDto) => {
            const {data, ...pagination} = listItemsDto;
            const entities = data.map((item) => this.listTransformerService.transformFgOrEntityToListItemEntity(item));
            const actions: Action[] = [];
            actions.push(ListActions.loadListItemsComplete({id, pagination}));
            if (clearBeforeAdd) {
              actions.push(ListActions.clear({id}));
            }
            actions.push(ListActions.addLoadedItems({id, entities}));

            if (notifyShowcaseId) {
              actions.push(CustomShowcaseActions.notifyCuratedShowcasesItemsUpdated({
                listId: id,
                showcaseId: notifyShowcaseId,
                loadedByList: {
                  entities,
                  totalResults: pagination.totalResults,
                  sortByString,
                },
              }));
            }

            return actions;
          }),
          catchError((err: HttpErrorResponse) => {
            const error = ListEffects.extractErrorOrUnknown(err);
            return of(ListActions.loadListItemsFailure({id, error}));
          }),
        );
    }),
  ));

  public updateEntityBookmarkedOnAddGuest$ = createEffect(() => this.actions$.pipe(
    ofType(ForLaterListActions.addToList),
    map(({entity}) => {
      return ListActions.setBookmarkButtonState({entityId: entity.id, bookmarkButtonState: BookmarkButtonState.LOGIN});
    }),
  ));

  public updateEntityBookmarkedOnRemoveGuest$ = createEffect(() => this.actions$.pipe(
    ofType(ForLaterListActions.removeFromList),
    map(({entity}) => {
      return ListActions.resetEntityBookmarkedState({entityId: entity.id});
    }),
  ));

  public updateEntityBookmarkedOnRemoveAuth$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.removeBookmarkViaButton),
    switchMap(({entity}) => {
      return this.store.select(ListReducer.getEntityBookmarked, {id: entity.id})
        .pipe(
          take(1),
          switchMap((entityBookmarked) => {
            const currentList = entityBookmarked.personalLists.length > 1
              ? entityBookmarked.personalLists.find(list => list.recordId === entity.selectedTabRecordId)
              : entityBookmarked.personalLists[0];
            if (currentList?.listIds?.length > 1) {
              return of(ListActions.setBookmarkButtonState({
                entityId: entity.id,
                personalLists: entityBookmarked.personalLists,
                bookmarkButtonState: BookmarkButtonState.SAVED_IN_MULTIPLE_LISTS,
              }));
            } else {
              return of(ListActions.removeBookmarkViaButtonSingleList({entity, listId: currentList.listIds[0]}));
            }
          }),
        );
    }),
  ));

  public loginFromBookmarkButton$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.loginFromBookmarkButton),
    withLatestFrom(this.store.select(isAuthorizedInKeycloak)),
    filter(([, isAuthorized]) => !isAuthorized),
    tap(([{entityId}]) => {
      const tree = this.router.createUrlTree([], {
        relativeTo: this.route,
        queryParams: {[ListEffects.queryParamSaveBookmarks]: entityId},
        queryParamsHandling: 'merge',
      }).toString();
      this.location.go(tree);
      this.keycloakService.startLogin();
    }),
  ), {dispatch: false});

  public loginFromBookmarksTab$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.loginFromBookmarksTab),
    withLatestFrom(this.store.select(isAuthorizedInKeycloak)),
    filter(([, isAuthorized]) => !isAuthorized),
    tap(() => {
      const tree = this.router.createUrlTree([], {
        relativeTo: this.route,
        queryParams: {[ListEffects.queryParamSaveBookmarks]: ListEffects.queryParamValueBookmarksTab},
        queryParamsHandling: 'merge',
      }).toString();
      this.location.go(tree);
      this.keycloakService.startLogin();
    }),
  ), {dispatch: false});

  public shouldSaveBookmarksOnPageLoad$ = createEffect(() => (
    zip(
      this.actions$.pipe(ofType(routerNavigatedAction), first()),
      this.actions$.pipe(ofType(loggedIn)),
    ).pipe(
      pluck('0', 'payload', 'routerState', 'root', 'queryParams', ListEffects.queryParamSaveBookmarks),
      tap((entityIdTriggeredLogin) => {
        if (entityIdTriggeredLogin) {
          this.router.navigate([], {
            queryParams: {[ListEffects.queryParamSaveBookmarks]: null},
            queryParamsHandling: 'merge',
          });
        }
      }),
      map((entityIdTriggeredLogin) => ListActions.shouldSaveBookmarksOnPageLoad({entityIdTriggeredLogin})),
    )
  ));

  public notifyListsLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.notifyListsLoaded),
    map(({listDtos = []}) => ListActions.loadListsComplete({
      listsLoaded: listDtos.map((listDto) => {
        const {data, sortedBy, ...pagination} = listDto.items;
        return {
          ...this.listTransformerService.transformListDtoToList(listDto),
          entities: data.map((item) => this.listTransformerService.transformFgOrEntityToListItemEntity(item)),
          sort: this.listTransformerService.transformListSortByStringToSort(sortedBy),
          pagination,
        };
      }),
    })),
  ));

  public repopulateEntitiesBookmarkedGuest$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.notifyNewEntitiesOnPage),
    pluck('entities'),
    withLatestFrom(
      this.store.select(isAuthorizedInKeycloak),
      this.store.select(isStaffAuthorizedInKeycloak),
    ),
    filter(([, isPatronAuthorized, isStaffAuthorized]) => !(isPatronAuthorized || isStaffAuthorized)),
    withLatestFrom(this.store.select(ForLaterListReducer.getForLaterListItemSearchResults)),
    map(([[entities], forLaterListItems]) => {
      const bookmarkedStatuses: BookmarkedStatus[] = entities
        .filter((entity) => forLaterListItems.some((listItem) => listItem.id === entity.id))
        .map((entity) => ({
          entityId: entity.id,
          personalLists: entity.personalLists || [],
          bookmarkButtonState: BookmarkButtonState.SAVED,
        }));

      return ListActions.setEntitiesBookmarkedState({bookmarkedStatuses});
    }),
  ));

  public repopulateEntitiesBookmarkedAuth$ = createEffect(() => (
    combineLatest(
      [
        this.actions$.pipe(ofType(ListActions.notifyNewEntitiesOnPage)).pipe(pluck('entities')),
        this.actions$.pipe(ofType(loggedIn, staffLoggedIn)),
      ],
    ).pipe(
      withLatestFrom(this.store.select(ListReducer.getEntitiesBookmarked)),
      map(([[entities], entitiesBookmarked]) => (
        ListActions.setEntitiesBookmarkedState({
          bookmarkedStatuses: entities
            .map((entity) => {
              let personalLists: PersonalList[] = [];
              let bookmarkButtonState = BookmarkButtonState.NONE;
              if (entity.personalLists && entity.personalLists.length) {
                personalLists = entity.personalLists;
                bookmarkButtonState = BookmarkButtonState.SAVED;
              }

              const entityBookmarked = ListEffects.getEntityBookmarkedSetOnLoginOrDefault(bookmarkButtonState, entitiesBookmarked[entity.id]);

              return {entityId: entity.id, personalLists, ...entityBookmarked};
            }),
        })
      )),
    )
  ));

  public setBookmarkButtonStatusOnListItemsChange$ = createEffect(() => (
    merge(
      this.actions$.pipe(ofType(ListActions.addBookmarksComplete)),
      this.actions$.pipe(ofType(ListActions.removeBookmarksComplete)),
      this.actions$.pipe(ofType(ListActions.deleteListComplete)),
    ).pipe(
      withLatestFrom(this.store.select(ListReducer.getEntitiesBookmarked)),
      map(([, entitiesBookmarked]) => (
        ListActions.setEntitiesBookmarkedState({
          bookmarkedStatuses: Object.entries(entitiesBookmarked).map(([entityId, entityBookmarked]) => {
            let bookmarkButtonState = entityBookmarked.bookmarkButtonState;

            if (bookmarkButtonState === BookmarkButtonState.NONE && entityBookmarked.personalLists.length) {
              bookmarkButtonState = BookmarkButtonState.SAVED;
            } else if (bookmarkButtonState === BookmarkButtonState.SAVED && !entityBookmarked.personalLists.length) {
              bookmarkButtonState = BookmarkButtonState.NONE;
            }

            const {setOnLogin, ...rest} = entityBookmarked;
            return {...rest, entityId, bookmarkButtonState};
          }),
        })
      )),
    )
  ));

  public saveBookmarksIfAble$ = createEffect(() => zip(
    this.actions$.pipe(ofType(ListActions.shouldSaveBookmarksOnPageLoad)),
    this.actions$.pipe(ofType(ListActions.loadListsComplete)),
  ).pipe(
    withLatestFrom(this.store.select(ForLaterListReducer.getForLaterListItemSearchResults), this.store.select(ListReducer.getForLaterList)),
    switchMap(([[{entityIdTriggeredLogin}], forLaterEntities, forLaterList]) => {
      const actionsOnMultipleBookmarks: Action[] = [ListActions.openSaveMultipleBookmarksModal({entities: forLaterEntities})];
      const isTriggeredByBookmarksTab = entityIdTriggeredLogin === ListEffects.queryParamValueBookmarksTab;

      if (!forLaterEntities.length || !forLaterList) {
        return of(ListActions.notifyWillSaveBookmarksOnLogin({willSaveBookmarks: false}));
      }

      if (forLaterEntities.length > 1) {
        if (isTriggeredByBookmarksTab) {
          actionsOnMultipleBookmarks.push(toggleBookmarks());
        }
        return of(...actionsOnMultipleBookmarks);
      }

      const actionsOnTrigger: Action[] = [];
      const actionsOnSuccess: Action[] = [ForLaterListActions.clear()];
      const actionsOnFail: Action[] = [ListActions.openBookmarksSaveResultModal({success: false})];

      if (isTriggeredByBookmarksTab) {
        actionsOnTrigger.push(toggleBookmarks());
        actionsOnSuccess.push(ListActions.openBookmarksSaveResultModal({success: true}));
      } else if (entityIdTriggeredLogin) {
        actionsOnTrigger.push(
          ListActions.setBookmarkButtonState({
            entityId: entityIdTriggeredLogin,
            bookmarkButtonState: BookmarkButtonState.LOADING,
            setOnLogin: true,
          }),
          ListActions.focusBookmarkButton({entityId: entityIdTriggeredLogin}),
        );
        actionsOnSuccess.push(
          ListActions.setBookmarkButtonState({
            entityId: entityIdTriggeredLogin,
            bookmarkButtonState: BookmarkButtonState.SAVED_TO_SINGLE_LIST,
            justAddedListId: forLaterList.id,
            setOnLogin: true,
          }),
          ListActions.focusBookmarkButton({entityId: entityIdTriggeredLogin}),
        );
        actionsOnFail.push(ListActions.resetEntityBookmarkedState({entityId: entityIdTriggeredLogin}));
      } else {
        actionsOnSuccess.push(
          ListActions.openBookmarksSaveResultModal({success: true}),
          ListActions.setBookmarkButtonState({
            entityId: forLaterEntities[0].id,
            bookmarkButtonState: BookmarkButtonState.SAVED,
            setOnLogin: true,
          }),
        );
      }

      return of(
        ...actionsOnTrigger,
        ListActions.notifyWillSaveBookmarksOnLogin({willSaveBookmarks: true}),
        ListActions.moveBookmarks({
          entities: forLaterEntities,
          toListIds: [forLaterList.id],
          actionsOnSuccess,
          actionsOnFail
        }),
      );
    }),
  ));

  public openBookmarksSaveResultModal$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.openBookmarksSaveResultModal),
    tap(({success}) => {
      const openModal = () => {
        const modalRef = this.modal.open(InformationModalComponent);
        modalRef.componentInstance.bodyTranslateKey = (success)
          ? 'listYourTemporaryBookmarksHaveBeenAdded'
          : 'listYourTemporaryBookmarksWereUnableToBeSaved';
      };
      this.modalQueueService.addToQueueAndStartIfPossible(openModal);
    }),
  ), {dispatch: false});

  public openSaveMultipleBookmarksModal$ = createEffect(() => this.actions$.pipe(
    ofType(ListActions.openSaveMultipleBookmarksModal),
    tap(({entities}) => {
      const openModal = () => {
        const modalRef = this.modal.open(SaveMultipleBookmarksModalComponent, {
          windowClass: 'inspire-custom-modal',
          keyboard: false,
          centered: true,
          beforeDismiss: () => {
            return modalRef.componentInstance.canDismiss();
          },
        });
        modalRef.componentInstance.listItemEntities = entities;
      };
      this.modalQueueService.addToQueueAndStartIfPossible(openModal);
    }),
  ), {dispatch: false});

  public clearBookmarksOnGuestSessionEnd$ = createEffect(() => this.actions$.pipe(
    ofType(endGuestSession),
    debounceTime(0),
    withLatestFrom(this.store.select(ListReducer.getEntitiesBookmarked)),
    switchMap(([, entitiesBookmarked]) => of(
      ForLaterListActions.clear(),
      ListActions.setEntitiesBookmarkedState({
        bookmarkedStatuses: Object.entries(entitiesBookmarked).map(([entityId]) => ({
          entityId,
          personalLists: [],
          bookmarkButtonState: BookmarkButtonState.NONE,
        })),
      }),
    )),
  ));

  public copyListUrl$ = createEffect(() => this.actions$.pipe(
    ofType(copyUrl),
    debounceTime(0),
    map(({id}) => {
      const url = this.makeListUrl(id);
      return writeUrl({id, url});
    }),
  ));

  public shareOnTwitter$ = createEffect(() => this.actions$.pipe(
      ofType(shareOnTwitter),
      switchMap(({id}) => {
        const url = this.makeListUrl(id);
        this.shareOnSocialMediaService.shareOnSocialMedia(SocialMediaType.TWITTER, url);
        return of(redirectToSocialMediaSuccess());
      }),
      catchError(() => {
        return EMPTY;
      })
    )
  );

  public shareOnFacebook$ = createEffect(() => this.actions$.pipe(
      ofType(shareOnFacebook),
      switchMap(({id}) => {
        const url = this.makeListUrl(id);
        this.shareOnSocialMediaService.shareOnSocialMedia(SocialMediaType.FACEBOOK, url);
        return of(redirectToSocialMediaSuccess());
      }),
      catchError(() => {
        return EMPTY;
      })
    )
  );

  constructor(
    private actions$: Actions,
    private readonly store: Store,
    private readonly listService: ListService,
    private readonly listTransformerService: ListTransformerService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly keycloakService: KeycloakService,
    private readonly modal: NgbModal,
    private readonly modalQueueService: ModalQueueService,
    private readonly windowRef: WindowRefService,
    private readonly shareOnSocialMediaService: ShareOnSocialMediaService,
  ) {
  }

  private makeListUrl(id: string): string {
    const tree = this.router.createUrlTree(['/library-list', 'list', id]).toString();
    return `${this.windowRef.origin()}${tree}`;
  }

  private static extractErrorOrUnknown(err: HttpErrorResponse): ListError {
    return (err.error?.status)
      ? err.error
      : {
        status: err.status,
        message: 'Unknown error',
      };
  }

  private static getEntityBookmarkedSetOnLoginOrDefault(
    defaultState: BookmarkButtonState,
    entityBookmarked?: ListReducer.EntityBookmarked,
  ): Pick<BookmarkedStatus, 'bookmarkButtonState' | 'justAddedListId'> {
    if (entityBookmarked?.setOnLogin) {
      const bookmarkedStatus: Pick<BookmarkedStatus, 'bookmarkButtonState' | 'justAddedListId'> = {
        bookmarkButtonState: entityBookmarked.bookmarkButtonState,
      };
      if (entityBookmarked.justAddedListId) {
        bookmarkedStatus.justAddedListId = entityBookmarked.justAddedListId;
      }

      return bookmarkedStatus;
    }

    return {bookmarkButtonState: defaultState};
  }

  private makePaginationParams = (pageNum = 0, pageSize = 999): ListPaginationParams => {
    return {pageNum, pageSize};
  }
}
